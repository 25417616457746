import React, { FC, useEffect, useState, useMemo } from 'react';
import { getNewsById } from 'services/news';
import Spinner from 'components/common/Spinner/Spinner';
import styles from './SingleNewsPage.module.scss';
import SectionHeading from 'components/common/SectionHeading/SectionHeading';
import Gap from 'components/common/Gap/Gap';
import Text from 'components/common/Text/Text';
import ImgPlaceholder from 'components/common/ImgPlaceholder/ImgPlaceholder';
import { Link } from 'gatsby';
import { Icon } from 'semantic-ui-react';
import { ROUTES } from 'types/routes';

interface SingleNewsPageProps {
  id?: string;
}

const SingleNewsPage: FC<SingleNewsPageProps> = ({ id }) => {
  const [news, setNews] = useState();

  useEffect(() => {
    getNewsById(id).then(news => {
      setNews(news);
    });
  }, []);

  const date = useMemo(
    () => (news ? new Date(news.updatedAt).toLocaleDateString() : ''),
    [news]
  );
  const style = useMemo(
    () =>
      news && news.image && news.image.url
        ? { backgroundImage: `url("${news.image.url}")` }
        : {},
    [news]
  );

  if (!news) return <Spinner />;

  return (
    <div className={styles.wrapper}>
      {news.image && news.image.url ? (
        <div className={styles.img} style={style} />
      ) : (
        <div className={styles.imgPlaceholderWrapper}>
          <ImgPlaceholder />
        </div>
      )}
      <SectionHeading
        largeHeading={news.title}
        className={styles.heading}
        inline
        withUnderline={false}
      />
      <Gap top="md" bottom="md">
        <Text color="neutral" fontSize="1.2rem">
          {date}
        </Text>
      </Gap>
      <Text className={styles.text}>{news.text}</Text>
      {(news.files && news.files.length && (
        <Gap top="md" bottom="md">
          <Text>Pliki do pobrania:</Text>
          <div>
            {news.files.map(file => (
              <div key={file.name}>
                <a href={file.url} noopener noreferrer target="_blank">
                  <Text>{file.name}</Text>
                </a>
              </div>
            ))}
          </div>
        </Gap>
      )) ||
        null}
      <Gap top="xl">
        <Link to={ROUTES.NEWS}>
          <Text color="neutral" fontSize="1.3rem" className={styles.goBackLink}>
            <Icon name="chevron left" />
            <span> Zobacz wszystkie aktualności</span>
          </Text>
        </Link>
      </Gap>
    </div>
  );
};

export default SingleNewsPage;
