import React, { useMemo } from 'react';
import AllNews from './AllNews/AllNews';
import SingleNewsPage from './SingleNewsPage/SingleNewsPage';
import Layout from 'components/common/Layout/Layout';
import Header from 'components/common/Header/Header';
import styles from './News.module.scss';

const News = () => {
  if (typeof window === 'undefined') return null;

  const urlParams = new URLSearchParams(window.location.search);
  const newsId = useMemo(() => urlParams.get('id'), [urlParams]);

  return (
    <Layout>
      <Header withBackground block />
      <main className={styles.main}>
        {newsId ? <SingleNewsPage id={newsId} /> : <AllNews />}
      </main>
    </Layout>
  );
};

export default News;
