import React, { FC, useState, useCallback } from 'react';
import cn from 'classnames';
import { graphql, useStaticQuery } from 'gatsby';
import { useNews } from 'hooks/news';
import Gap from 'components/common/Gap/Gap';
import SectionHeading from 'components/common/SectionHeading/SectionHeading';
import SingleNews from 'components/common/SingleNews/SingleNews';
import Spinner from 'components/common/Spinner/Spinner';
import { Row } from 'react-flexbox-grid';
import styles from './AllNews.module.scss';
import { Pagination, PaginationProps } from 'semantic-ui-react';

const query = graphql`
  query {
    allDataJson {
      edges {
        node {
          start {
            news {
              headingLarge
            }
          }
        }
      }
    }
  }
`;

const AllNews: FC<{}> = () => {
  const data = useStaticQuery(query);
  const { headingLarge } = data.allDataJson.edges[0].node.start.news;
  const [currentPage, setCurrentPage] = useState(1);
  const { news, nbOfPages, isLoading } = useNews(currentPage, true);

  const handlePaginationChange = useCallback(
    (
      _event: React.MouseEvent<HTMLAnchorElement>,
      { activePage }: PaginationProps
    ) => {
      setCurrentPage(Number(activePage));
    },
    [setCurrentPage]
  );

  // const paginationIconsProps = useMemo(
  //   () => ({
  //     firstItem: {
  //       'aria-label': 'First item',
  //       content: '«',
  //     },
  //     lastItem: {
  //       'aria-label': 'Last item',
  //       content: '»',
  //     },
  //     prevItem: {
  //       'aria-label': 'Previous item',
  //       content: '⟨',
  //     },
  //     nextItem: {
  //       'aria-label': 'Next item',
  //       content: '⟩',
  //     },
  //   }),
  //   []
  // );

  return (
    <>
      <Gap top="md">
        <SectionHeading largeHeading={headingLarge} color="primary" centered />
      </Gap>
      {isLoading ? (
        <Spinner />
      ) : (
        <Gap bottom="lg">
          <Gap bottom="lg">
            <Row around="xs">
              {news.length ? (
                news.map(news => (
                  <Gap top="xxl" key={news.id} className={styles.singleNews}>
                    <SingleNews {...news} />
                  </Gap>
                ))
              ) : (
                <Gap top="xxl">Brak aktualności</Gap>
              )}
            </Row>
          </Gap>
          {nbOfPages > 1 && (
            <div
              className={cn(styles.pagination, {
                [styles.hideLeftArrows]: currentPage === 1,
                [styles.hideRightArrows]: currentPage === nbOfPages,
              })}
            >
              <Pagination
                activePage={currentPage}
                onPageChange={handlePaginationChange}
                size="mini"
                totalPages={nbOfPages}
                // {...paginationIconsProps}
              />
            </div>
          )}
        </Gap>
      )}
    </>
  );
};

export default AllNews;
